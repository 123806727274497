import { admin2 } from '@/api/instance'

// => 預約
export const AppointmentReservationData = async ({
  shopId,
  start,
  end,
  splitType,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/shopAnalysis/appointmentOrderStart`,
    params: {
      start,
      end,
      splitType,
    },
  })
  return res
}

// => 會員數
export const CreatedMemberData = async ({ shopId, start, end, splitType }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/shopAnalysis/memberCreatedAt`,
    params: {
      start,
      end,
      splitType,
    },
  })
  return res
}

// => 電商訂單數
export const EcommerceOrderData = async ({ shopId, start, end, splitType }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/shopAnalysis/ecommerceOrderCreatedAt`,
    params: {
      start,
      end,
      splitType,
    },
  })
  return res
}
